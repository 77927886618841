<template>
  <v-card class="full-height d-flex flex-column" elevation="0">
    <v-card-title
      :style="{
        paddingTop: '8px',
        paddingBottom: '8px',
      }"
      class="widget-gradient white--text"
    >
      <div class="d-flex flex-grow-1 justify-space-between">
        <div>
          <span>{{ $t('general.tables') | capitalize }}</span>
        </div>
        <div>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div>
                <v-progress-circular
                  v-if="$wait.is('project.sbs.tables')"
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <v-icon
                  v-else
                  class="ant-icon"
                  @click="fetch(sbsObject?.project, sbsObject?.code)"
                  v-on="on"
                >
                  mdi-refresh
                </v-icon>
              </div>
            </template>
            <span>
              {{
                $t('system.sbsOverview.reload', { item: $t('general.tables') })
              }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text
      v-if="!sbsObject"
      class="pt-6 d-flex flex-grow-1 align-center justify-center"
    >
      {{ $t('system.sbsOverview.notSelected') }}
    </v-card-text>
    <div v-else class="d-flex flex-grow-1 flex-column overflow-y-auto">
      <v-expansion-panels
        v-if="sbsTables && sbsTables.length"
        v-model="openedInd"
        accordion
        flat
        focusable
        multiple
        tile
      >
        <v-expansion-panel
          v-for="table in sbsTables"
          :key="table.id"
          class="table-wrapper"
        >
          <v-expansion-panel-header>
            <v-row class="d-flex justify-space-between align-center">
              <div class="d-flex align-center">{{ table.name }}</div>
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <router-link
                    :to="`/project/${project.slug}/tables/${table.id}`"
                    class="table-link"
                    target="_blank"
                  >
                    <v-icon class="ant-icon ml-2" dense v-bind="attrs" v-on="on"
                      >mdi-open-in-new
                    </v-icon>
                  </router-link>
                </template>
                <span>{{ $t('general.openInNewTab') }}</span>
              </v-tooltip>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <dynamic-data-table
                :has-top="false"
                :is-loading="$wait.is(`table.sbs.records.search.${table.id}`)"
                :project-id="project.id"
                :table-columns="table.columns"
                :table-headers="getHeaders(table.columns)"
                :table-id="table.id"
                :table-records="getRecords(table.id)"
                :table-title="table.name"
                hide-footer
              ></dynamic-data-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else class="fill-height d-flex align-center justify-center">
        {{ $t('system.sbsOverview.noFound', { item: $t('general.tables') }) }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import { differenceBy } from 'lodash';

export default {
  name: 'SBSTablesWidget',
  components: { DynamicDataTable },
  props: {
    sbsObject: {
      type: Object,
      default: () => null,
    },
  },
  data: () => {
    return {
      openedInd: [],
    };
  },
  computed: {
    ...mapGetters(['sbsTables', 'getSbsTableRecords', 'project']),
  },
  watch: {
    sbsObject: {
      handler(val) {
        this.$store.commit('reset_sbs_state');
        this.openedInd = [];
        if (!val) {
          return;
        }
        const { project: projectId, code } = val;
        this.fetch(projectId, code);
      },
      immediate: true,
    },
    openedInd: {
      handler(oldV, newV) {
        if (oldV?.length < newV?.length) {
          return;
        }
        const [index] = differenceBy(oldV, newV);
        if (index !== undefined) {
          this.searchSbsOverviewRecords(index);
        }
      },
    },
  },
  methods: {
    ...mapActions(['searchSBSTableRecords']),
    fetch(projectId, code) {
      this.$store.dispatch('fetchProjectSBSTables', { projectId, code });
    },
    getHeaders(cols) {
      let headers = [];
      cols.forEach((col) => {
        const header = { text: col.name, value: col.name };
        headers.push(header);
      });
      return headers;
    },
    searchSbsOverviewRecords(index) {
      const body = {
        project: {
          id: this.sbsObject.project,
        },
        table: {
          id: this.sbsTables?.[index]?.id,
        },
        search: { phrase: this.sbsObject?.code },
        searchFields: ['SBS'],
      };
      this.searchSBSTableRecords(body);
    },
    getRecords(id) {
      return this.getSbsTableRecords(id) || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .table-link {
    text-decoration: none;
    color: inherit;
    padding: 10px 15px;

    &:hover {
      color: var(--v-primary-base);
    }
  }

  ::v-deep .v-expansion-panel-content {
    .v-expansion-panel-content__wrap {
      padding: 0 10px;
    }
  }
}

::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0;
}
</style>
