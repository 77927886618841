<template>
  <div class="d-flex flex-grow-1 flex-scroll-height">
    <div class="ant-panel ant-panel--white d-flex flex-grow-1">
      <dynamic-data-table
        :can-delete="
          selectedSbsVersion === null &&
          (sbsManagementPermissions.delete || $can('update', 'sbs-records'))
        "
        :can-edit="
          selectedSbsVersion === null &&
          (sbsManagementPermissions.update || $can('delete', 'sbs-records'))
        "
        :can-refresh="true"
        :can-select="true"
        :has-options="true"
        :is-loading="sbsRecordsStatus === 'loading'"
        :table-headers="sbsHeaders"
        :table-records="sbsRecords"
        class="flex-grow-1"
        export-file-name="sbs_records"
        table-title="SBS"
        @deleteItem="setupDelete"
        @editItem="setupEdit"
        @reloadData="fetchSbsRecords"
        @selectedRecords="selectedRecords"
      >
        <template #table-actions>
          <v-chip
            v-if="selectedSbsVersion"
            close
            color="primary"
            small
            @click:close="deselectSbsVersion"
          >
            {{ selectedSbsVersion.label }}
          </v-chip>
          <div class="mx-2">
            <v-text-field
              v-model="sbsSearchValue"
              clearable
              dense
              filled
              hide-details
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              single-line
              @keydown.enter="searchSbsRecords"
              @click:clear="fetchSbsRecords"
              @keydown.esc="fetchSbsRecords"
            />
          </div>
          <v-dialog
            v-if="selectedSbsVersion === null"
            key="add-update-sbs-object"
            v-model="sbsDialog"
            max-width="500px"
            @click:outside="closeSbsDialogs"
            @keydown.esc="closeSbsDialogs"
          >
            <template #activator="{ on, attrs }">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    :disabled="
                      $can('create', 'sbs-records')
                        ? false
                        : !sbsManagementPermissions.create
                    "
                    class="ant-icon"
                    dense
                    @click="sbsDialog = true"
                    v-on="on"
                  >
                    mdi-plus
                  </v-icon>
                </template>
                <span>Add Sbs Object</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-title class="justify-center text-uppercase headline">
                {{ sbsItem.id ? 'Update' : 'Add' }} SBS Object
              </v-card-title>
              <v-divider />
              <div class="px-10 pb-5">
                <v-form ref="sbs-form" @submit.prevent>
                  <ant-input :is-optional="false" label="Code">
                    <template #input-field>
                      <v-text-field
                        v-model="sbsItem.code"
                        :rules="[rules.required, rules.maxLength]"
                        counter="250"
                        dense
                        filled
                        hide-details
                        maxlength="250"
                        placeholder="Code"
                      />
                    </template>
                  </ant-input>

                  <ant-input :is-optional="true" label="Parent">
                    <template #input-field>
                      <v-combobox
                        v-model="sbsItem.parent"
                        :disabled="sbsParentOptions.length === 0"
                        :items="sbsParentOptions"
                        clearable
                        dense
                        filled
                        hide-details
                        item-text="code"
                        item-value="code"
                        placeholder="Parent"
                      >
                        <template #item="{ item }">
                          <div v-if="item.label" class="d-flex align-center">
                            {{ item.label }}
                            <v-divider class="mx-2" vertical />
                            <span style="font-size: 11px; color: grey">
                              {{ item.code }}
                            </span>
                          </div>
                          <div v-else>
                            {{ item.code }}
                          </div>
                        </template>
                      </v-combobox>
                    </template>
                  </ant-input>

                  <ant-input :is-optional="true" label="Label">
                    <template #input-field>
                      <v-text-field
                        v-model="sbsItem.label"
                        clearable
                        counter="250"
                        dense
                        filled
                        hide-details
                        maxlength="250"
                        placeholder="Label"
                      />
                    </template>
                  </ant-input>
                </v-form>
              </div>

              <v-card-actions class="ant-dialog-actions-bg ant-border-top">
                <v-spacer />
                <v-btn color="error" small text @click="closeSbsDialogs">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  small
                  @click="saveSbsRecord"
                >
                  save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template #table-options-menu>
          <v-list-item
            v-if="
              selectedSbsVersion === null &&
              (sbsManagementPermissions.import || $can('import', 'sbs-records'))
            "
            @click.stop="$refs['csv-import'].$refs.input.click()"
          >
            <v-list-item-icon style="margin-right: 10px">
              <v-icon dense> mdi-file-import</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Import CSV</v-list-item-title>
            <v-file-input
              ref="csv-import"
              v-model="csvImportFile"
              accept=".csv"
              style="display: none"
              @change="importCsvData()"
            />
          </v-list-item>
          <v-list-item
            v-if="tableSelection.length > 0 && selectedSbsVersion === null"
            @click.stop="sbsDeleteMultipleDialog = true"
          >
            <v-list-item-icon style="margin-right: 10px">
              <v-icon>mdi-delete-sweep</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Delete {{ tableSelection.length }} records
            </v-list-item-title>
          </v-list-item>
        </template>
      </dynamic-data-table>
      <delete-dialog
        :dialog="sbsDeleteDialog"
        :title="`Are you sure you want to remove '${sbsItem.code}'?`"
        @closeDialog="closeSbsDialogs"
        @deleteAction="removeSbsRecord"
      />
      <delete-dialog
        :dialog="sbsDeleteMultipleDialog"
        :title="`Are you sure you want to remove ${tableSelection.length} ${
          tableSelection.length > 1 ? 'records' : 'record'
        }?`"
        @closeDialog="closeSbsDialogs"
        @deleteAction="deleteSelection()"
      />
    </div>

    <panel-resizable
      :collapsible="false"
      :default-width="300"
      :min-width="324"
      class="ant-glass-background ant-glass-background--plain full-height ant-border-left"
      side="right"
    >
      <div class="ant-panel pa-2 d-flex flex-column">
        <div class="d-flex px-2">
          <div class="text-subtitle-2 py-1">Versions</div>
          <v-spacer />
          <v-dialog
            key="add-update-sbs-version"
            v-model="versionDialog"
            width="500"
          >
            <template #activator="{ on, attrs }">
              <v-tooltip bottom>
                <template #activator="{ on: onTooltip }">
                  <v-icon
                    :disabled="!$can('update', 'sbs-records')"
                    class="ant-icon"
                    dense
                    v-on="{ ...on, ...onTooltip }"
                  >
                    mdi-plus
                  </v-icon>
                </template>
                <span>New SBS version</span>
              </v-tooltip>
            </template>

            <v-card>
              <v-card-title class="justify-center text-uppercase headline">
                {{ versionItem.id ? 'Update' : 'Add' }} SBS version
              </v-card-title>
              <v-divider />
              <div class="px-10 pb-5">
                <v-form ref="form" @submit.prevent>
                  <ant-input :is-optional="false" label="Label">
                    <template #input-field>
                      <v-text-field
                        v-model="versionItem.label"
                        :rules="[rules.required, rules.maxLength]"
                        counter="50"
                        dense
                        filled
                        hide-details
                        maxlength="50"
                        placeholder="label"
                      />
                    </template>
                  </ant-input>
                </v-form>
              </div>
              <v-card-actions class="ant-dialog-actions-bg ant-border-top">
                <v-spacer />
                <v-btn color="error" small text @click="closeDialog">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  small
                  @click="saveSbsVersion"
                >
                  save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <div class="overflow-y-auto flex-grow-1 overflow-x-hidden">
          <v-timeline v-if="sbsVersionsStatus === 'success'" align-top dense>
            <v-timeline-item
              v-for="version in sortedVersions"
              :key="version.id"
              :small="selectedSbsVersion !== version"
            >
              <div class="d-flex" @click="selectSbsVersion(version)">
                <div
                  class="d-flex flex-column flex-grow-1"
                  style="cursor: pointer"
                >
                  <strong>{{ version.label }}</strong>
                  <div class="text-caption">
                    {{ getParsedDate(version.created_at) }}
                  </div>
                </div>
                <v-icon dense @click.stop="setupSbsVersionDelete(version)">
                  mdi-delete
                </v-icon>
              </div>
            </v-timeline-item>
          </v-timeline>
          <div v-else class="d-flex align-center justify-center full-height">
            <ant-loading />
          </div>
          <delete-dialog
            :dialog="versionDeleteDialog"
            :title="`Are you sure you want to remove '${versionItem.label}'?`"
            @closeDialog="closeDialog"
            @deleteAction="deleteSbsVersion"
          />
        </div>
      </div>
    </panel-resizable>
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog';
import FileHandlerService from '@/services/file-handler';
import AntLoading from '@/components/AntLoading';
import moment from 'moment';
import { getSbsProjectPermissions } from '@/services/api/sbs.api';
import AntInput from '@/components/AntInput.vue';
import PanelResizable from '@/components/Project/PanelResizable';

export default {
  name: 'SbsManagement',
  components: {
    AntInput,
    AntLoading,
    DeleteDialog,
    DynamicDataTable,
    PanelResizable,
  },
  data: () => {
    return {
      sbsHeaders: [
        {
          text: 'code',
          value: 'code',
        },
        {
          text: 'parent',
          value: 'parent',
        },
        {
          text: 'label',
          value: 'label',
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      sbsItem: {
        code: null,
        parent: null,
        label: null,
      },
      sbsUpdateItem: {},
      sbsDialog: false,
      sbsDeleteDialog: false,
      tableSelection: [],
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          (value && value.length < 250) || 'Max 250 characters',
      },
      csvImportFile: undefined,
      sbsDeleteMultipleDialog: false,
      versionDialog: false,
      versionItem: {},
      versionDeleteDialog: false,
      sbsManagementPermissions: {},
      sbsSearchValue: null,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'sbsRecords',
      'sbsRecordsStatus',
      'sbsVersions',
      'sbsVersionsStatus',
      'selectedSbsVersion',
    ]),
    sbsParentOptions() {
      return this.sbsRecords.filter((record) => record.id !== this.sbsItem.id);
    },
    sortedVersions() {
      return [...this.sbsVersions].sort((a, b) =>
        moment(b.created_at).diff(moment(a.created_at))
      );
    },
  },
  watch: {
    selectedSbsVersion(value) {
      this.fetchSbsRecords();
    },
  },
  mounted() {
    this.$store.commit('close_sbs_sidebar');
    this.$store.dispatch('loadSbsVersions', { projectId: this.project.id });
    this.fetchSbsRecords();
    getSbsProjectPermissions(this.project.id).then(
      (response) => (this.sbsManagementPermissions = response)
    );
  },
  methods: {
    searchSbsRecords() {
      this.$store.dispatch('searchSbsRecords', {
        searchValue: this.sbsSearchValue,
      });
    },
    deselectSbsVersion() {
      this.$store.commit('select_sbs_version', null);
    },
    selectSbsVersion(version) {
      this.$store.commit('select_sbs_version', version);
    },
    getParsedDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    closeDialog() {
      this.versionDialog = false;
      this.versionDeleteDialog = false;
      this.versionItem = {};
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    saveSbsVersion() {
      if (this.$refs.form.validate()) {
        let body = {
          label: this.versionItem.label,
        };

        this.$store
          .dispatch('createSbsVersion', { projectId: this.project.id, body })
          .then(() => {
            this.closeDialog();
          });
      }
    },
    setupSbsVersionDelete(item) {
      this.versionItem = Object.assign({}, item);
      this.versionDeleteDialog = true;
    },
    deleteSbsVersion() {
      this.$store
        .dispatch('deleteSbsVersion', {
          projectId: this.project.id,
          versionId: this.versionItem.id,
        })
        .then(this.closeDialog);
    },
    fetchSbsRecords() {
      this.sbsSearchValue = null;
      this.$store.dispatch('loadSbsRecords', { projectId: this.project.id });
    },
    setupEdit(sbs) {
      this.sbsItem = Object.assign({}, sbs);
      this.sbsUpdateItem = Object.assign({}, sbs);
      this.sbsDialog = true;
    },
    setupDelete(sbs) {
      this.sbsItem = Object.assign({}, sbs);
      this.sbsDeleteDialog = true;
    },
    closeSbsDialogs() {
      this.sbsItem = Object.assign(
        {},
        {
          code: null,
          parent: null,
          title: null,
        }
      );
      this.sbsUpdateItem = Object.assign({}, {});
      this.sbsDialog = false;
      this.sbsDeleteDialog = false;
      this.sbsDeleteMultipleDialog = false;
      if (this.$refs['sbs-form']) {
        this.$refs['sbs-form'].reset();
      }
    },
    selectedRecords(records) {
      this.tableSelection = records;
    },
    saveSbsRecord() {
      if (this.$refs['sbs-form'].validate()) {
        if (this.sbsItem.id !== undefined) {
          // update sbs object
          const data = this.sbsItem;
          let body = {};

          for (let key of Object.keys(data)) {
            if (this.sbsUpdateItem[key] !== data[key]) {
              // only add changes to body
              if (key === 'parent' && data[key]) {
                body[key] = data[key].code;
              } else {
                body[key] = data[key];
              }
            }
          }

          this.$store
            .dispatch('updateSbsRecord', {
              projectId: this.project.id,
              sbsId: this.sbsItem.id,
              data: body,
            })
            .then(() => this.closeSbsDialogs());
        } else {
          // create object
          const data = this.sbsItem;
          let body = {};

          for (let key of Object.keys(data)) {
            if (data[key] !== null) {
              if (key === 'parent') {
                body[key] = data[key].code;
              } else {
                body[key] = data[key];
              }
            }
          }

          this.$store
            .dispatch('createSbsRecord', {
              projectId: this.project.id,
              data: body,
            })
            .then(() => this.closeSbsDialogs());
        }
      }
    },

    removeSbsRecord() {
      this.$store
        .dispatch('deleteSbsRecord', {
          projectId: this.project.id,
          sbsId: this.sbsItem.id,
        })
        .then(() => {
          this.closeSbsDialogs();
        });
    },

    deleteSelection() {
      let body = {
        records: this.tableSelection.map((record) => record.id),
      };

      this.$store
        .dispatch('deleteMultipleSbsRecords', {
          projectId: this.project.id,
          body,
        })
        .then(() => {
          this.tableSelection = [];
          this.fetchSbsRecords();
          this.closeSbsDialogs();
        });
    },

    async importCsvData() {
      if (this.csvImportFile.name.split('.').pop() !== 'csv') {
        this.$store.commit('showNotification', {
          content: 'Only CSV files are accepted',
          color: 'error',
        });
        this.csvImportFile = undefined;
      } else {
        let body = {};

        await FileHandlerService.handleFile(this.csvImportFile).then(
          (value) => {
            body.records = value.data;
          }
        );

        this.$store
          .dispatch('importSbsRecords', { projectId: this.project.id, body })
          .then(() => {
            this.csvImportFile = undefined;
            this.fetchSbsRecords();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active-version {
  background-color: white;
}
</style>
