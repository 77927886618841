<template>
  <div class="d-flex flex-column">
    <v-tabs class="flex-grow-0 ant-border-bottom">
      <v-tab @change="$router.push({ name: 'sbs-overview' })">Overview</v-tab>
      <v-tab
        :disabled="!$can('access', 'sbs-management')"
        @change="$router.push({ name: 'sbs-management' })"
      >
        Management
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'SbsHome',
  components: { AntLoading },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(['project']),
  },
  mounted() {
    this.$store.commit('open_sbs_sidebar');
  },
  destroyed() {
    this.$store.commit('close_sbs_sidebar');
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
